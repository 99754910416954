import React, { PureComponent, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import ReactTable from 'react-table';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import DataTablePagination from 'Components/DataTables/pagination';
import {
	warehousesFindAllByPageAndOptions,
	errorReset,
} from 'Redux/actions';
import * as warehousesReducer from 'Redux/warehouses/reducer';
import IntlMessages from 'Util/IntlMessages';

class PageWarehouses extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
			tableState: {},
		};
		this.breadcrumb = [
			{ id: 'menu.warehouse', href: '/warehouse/list' },
			{ id: 'menu.warehouse.warehouse-list', href: '/warehouse/list' },
		];
		this.columns = [
			{
				id: 'wh_code',
				Header: 'Code',
				width: 150,
				accessor: (d) => (
					<NavLink to={ `/app/warehouse/edit/${d.wh_code}` }>
						{ d.wh_code }
					</NavLink>
				),
			},
			{
				id: 'wh_desc',
				Header: 'Name',
				accessor: (d) => (
					<NavLink to={ `/app/warehouse/edit/${d.wh_code}` }>
						{ d.wh_desc }
					</NavLink>
				),
			},
			{
				Header: 'Phone',
				accessor: 'wh_tel',
			},
			{
				Header: 'Address',
				accessor: 'wh_add',
			},
			{
				id: 'wh_type',
				Header: 'Type',
				width: 100,
				accessor: (d) => {
					let type = d.wh_type;
					switch (d.wh_type) {
					case 'C':
						type = 'Consignment';
						break;
					case 'S':
						type = 'Shop';
						break;
					case 'W':
						type = 'Warehouse';
						break;
					}
					return type;
				},
			},
			{
				id: 'wh_status',
				Header: 'Status',
				width: 100,
				accessor: (d) => {
					let className = 'badge-primary';
					let status = d.wh_status;
					switch (d.wh_status) {
					case 'A':
						status = 'Active';
						className = 'badge-warning';
						break;
					case 'P':
						status = 'Pending';
						className = 'badge-danger';
						break;
					case 'C':
						status = 'Closed';
						className = 'badge-light';
						break;
					}
					return (
						<span className={ classnames('badge', 'badge-pill', 'text-uppercase', className) }>{ status }</span>
					);
				},
			},
			{
				id: 'action',
				Header: '',
				width: 50,
				accessor: (d) => (
					<NavLink className="btn btn-xs btn-outline-primary" to={ `/app/warehouse/edit/${d.wh_code}` }>
						<i className="simple-icon-pencil" />
					</NavLink>
				)
			},
		];
		this.fetchData = this.fetchData.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		this.props.errorReset('warehouses');
	}

	fetchData(state) {
		const { keyword } = this.state;
		let params = {
			page: state.page + 1,
			size: state.pageSize,
			status: 'all',
		};
		if (state.sorted.length > 0) {
			params.sort_column = state.sorted[0].id;
			params.sort_direction = state.sorted[0].desc ? 'desc' : 'asc';
		}
		if (keyword) {
			params.keyword = keyword;
		}
		this.setState({
			tableState: state,
		});
		this.props.warehousesFindAllByPageAndOptions(params);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		}, this.onSubmitHandler);
	}

	onSubmitHandler(e) {
		e && e.preventDefault();
		const { tableState } = this.state;
		this.fetchData(tableState);
	}

	render() {
		const { warehousesByPage } = this.props;
		const { keyword } = this.state;
		const status = this.props.status.get('warehouses');
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="warehouses.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>

						<form className="mb-3" onSubmit={ this.onSubmitHandler }>
							<div className="search d-block mr-1 mb-1 align-top">
								<input
									type="text"
									placeholder="Search by warehouse code, description, address, phone, fax, login URL, IP whitelist, BSSID, or domain name whitelist"
									className="w-100 bg-transparent"
									value={ keyword }
									onChange={ this.onChangeKeywordHandler }
								/>
							</div>
						</form>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<ReactTable
									manual
									data={ warehousesByPage.get('data') }
									pages={ warehousesByPage.get('max_page') }
									loading={ status === 'fetching' }
									onFetchData={ this.fetchData }
									columns={ this.columns }
									minRows={ 0 }
									showPageJump={ false }
									showPageSizeOptions={ true }
									PaginationComponent={ DataTablePagination }
								/>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	warehousesReducer.selector,
	{
		warehousesFindAllByPageAndOptions,
		errorReset,
	}
)(PageWarehouses);