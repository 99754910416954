import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Row, Card, CardBody, Form, Button } from 'reactstrap';
import LoadingOverlay from 'Components/LoadingOverlay';
import CustomFormGroup from 'Components/CustomFormGroup';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import { NotificationManager } from 'Components/ReactNotifications';
import IntlMessages from 'Util/IntlMessages';
import {
	companiesCreate,
	companiesUpdate,
	companiesGetDetails,
	companiesGetOptions,
	errorReset,
} from 'Redux/actions';
import * as companiesReducer from 'Redux/companies/reducer';

class PageCompany extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			company: new Map({
				status: null,
				name: '',
				description: '',
				phone: '',
				address: '',
				workshop_address: '',
				contact: '',
				category: null,
				currency: null,
				wh_codes: [],
			}),
		};
		this.fields = [
			{ id: 'name', type: 'text', label: 'Company Name' },
			{ id: 'description', type: 'textarea', label: 'Description' },
			{ id: 'phone', type: 'text', label: 'Phone' },
			{ id: 'address', type: 'textarea', label: 'Address' },
			{ id: 'workshop_address', type: 'textarea', label: 'Workshop Address' },
			{ id: 'contact', type: 'text', label: 'Contact Person' },
			{ id: 'category', type: 'react-select', label: 'Category', onChange: this.onChangeCategoryHandler.bind(this) },
			{ id: 'currency', type: 'react-select', label: 'Currency', onChange: this.onChangeCurrencyHandler.bind(this) },
			{ id: 'wh_codes', type: 'react-select', label: 'Related Warehouses', onChange: this.onChangeWarehouseHandler.bind(this), isMulti: true },
			{ id: 'status', type: 'react-select', label: 'Status', onChange: this.onChangeStatusHandler.bind(this) },
		];
		this.breadcrumb = [
			{ id: 'menu.company', href: '/company/list' },
			{ id: 'company.heading', href: `/company/${this.getId(props) ? `edit/${this.getId(props)}` : 'create'}` },
		];
		this.getId = this.getId.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const id = this.getId();
		if (id) {
			this.props.companiesGetDetails(id);
		}
		this.props.companiesGetOptions();
		this.props.errorReset('company');
	}

	componentDidUpdate(prevProps) {
		const id = this.getId();
		if (id) {
			const prevCompany = prevProps.company.get(id);
			const company = this.props.company.get(id);
			if (company && company !== prevCompany) {
				this.setState({
					company: this.state.company.merge(company),
				});
			}
		}

		const current = this.props.current;
		const prevStatus = prevProps.status.get('company');
		const status = this.props.status.get('company');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted': {
				NotificationManager.success(
					`The company is ${id ? 'updated' : 'created'} successfully.`,
					`Company ${id ? 'Updated' : 'Created'}`
				);
				this.props.history.push(`/app/company/edit/${current}`);
				const company = this.props.company.get(current);
				this.setState({
					company: this.state.company.merge(company),
				});
			}
				break;
			case 'has-errors':
				NotificationManager.error(
					`Error occured! The company is NOT ${id ? 'updated' : 'created'}.`,
					`Company ${id ? 'Updated' : 'Created'} Failed`
				);
				break;
			}
		}
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return parseInt(props.match.params.companyId);
	}

	onChangeHandler(e) {
		let { company } = this.state;
		this.setState({
			company: company.set(e.target.id, e.target.value),
		});
	}

	onChangeCategoryHandler(selectedOption) {
		let { company } = this.state;
		this.setState({
			company: company.set('category', selectedOption),
		});
	}

	onChangeCurrencyHandler(selectedOption) {
		let { company } = this.state;
		this.setState({
			company: company.set('currency', selectedOption),
		});
	}

	onChangeWarehouseHandler(selectedOption) {
		let { company } = this.state;
		this.setState({
			company: company.set('wh_codes', selectedOption),
		});
	}

	onChangeStatusHandler(selectedOption) {
		let { company } = this.state;
		this.setState({
			company: company.set('status', selectedOption),
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const id = this.getId();
		let company = this.state.company.toJS();
		company.category = company.category ? company.category.value : null;
		company.currency = company.currency ? company.currency.value : null;
		company.wh_codes = company.wh_codes ? company.wh_codes.map((warehouse) => warehouse.value) : null;
		company.status = company.status ? company.status.value : null;
		if (!id) {
			this.props.companiesCreate(company);
		} else {
			this.props.companiesUpdate(company);
		}
	}

	render() {
		const { options, errors } = this.props;
		const { company } = this.state;
		const status = this.props.status.get('company');
		const optionsStatus = this.props.status.get('options');
		const isLoading = status === 'fetching' || status === 'submitting' || optionsStatus === 'fetching';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="company.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx md="10" className="mx-auto">
						<Card>
							<LoadingOverlay active={ isLoading }>
								<CardBody>
									<Form>
										{
											this.fields.map((field) => (
												<CustomFormGroup
													key={ `page-company-${field.id}` }
													id={ field.id }
													label={ field.label }
													type={ field.type }
													value={ company.get(field.id) }
													disabled={ isLoading }
													errors={ errors && errors.detail[field.id] }
													onChange={ field.onChange ? field.onChange : this.onChangeHandler }
													options={ options.get(field.id) }
													isMulti={ field.isMulti }
												/>
											))
										}

										<div className="mt-4 text-center">
											<Button size="lg" color="primary" onClick={ this.onSubmitHandler } disabled={ isLoading }>
												SAVE
											</Button>
										</div>
									</Form>
								</CardBody>
							</LoadingOverlay>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	companiesReducer.selector,
	{
		companiesCreate,
		companiesUpdate,
		companiesGetDetails,
		companiesGetOptions,
		errorReset,
	}
)(PageCompany);