import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Card, CardBody, Table, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { Colxx, Separator } from 'Components/CustomBootstrap';
import BreadcrumbContainer from 'Components/BreadcrumbContainer';
import { NotificationManager } from 'Components/ReactNotifications';
import LoadingOverlay from 'Components/LoadingOverlay';
import IntlMessages from 'Util/IntlMessages';
import {
	configsFindAllByGroup,
	configsBatchSetConfig,
	errorReset,
} from 'Redux/actions';
import * as configsReducer from 'Redux/configs/reducer';
import 'rc-switch/assets/index.css';

class PageConfigAppSettings extends PureComponent {
	constructor() {
		super();
		this.breadcrumb = [
			{ id: 'menu.config' },
			{ id: 'menu.config.app-settings', href: '/config/app-settings' },
		];
		this.state = {
			minumumDepositPercentage: 0,
		};
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onSaveHandler = this.onSaveHandler.bind(this);
	}

	componentDidMount() {
		this.props.configsFindAllByGroup('app');
		this.props.errorReset('configs');
	}

	componentDidUpdate(prevProps) {
		const prevConfigs = prevProps.configs.get('app');
		const configs = this.props.configs.get('app');
		if (configs && configs !== prevConfigs) {
			let newState = {};
			configs.forEach((config) => {
				switch (config.name) {
				case 'minimum_deposit_percentage':
					newState.minumumDepositPercentage = config.value;
					break;
				}
			});
			this.setState(newState);
		}

		const prevStatus = prevProps.status.get('configs');
		const status = this.props.status.get('configs');
		if (status && prevStatus !== status) {
			switch (status) {
			case 'submitted':
				NotificationManager.success(
					'The app settings are updated successfully.',
					'Config Updated'
				);
				break;
			case 'has-errors':
				NotificationManager.error(
					'Error occured! The app settings are NOT updated.',
					'Config Update Failed'
				);
				break;
			}
		}
	}

	onChangeHandler(e) {
		this.setState({
			minumumDepositPercentage: e.target.value,
		});
	}

	onSaveHandler() {
		const { minumumDepositPercentage } = this.state;
		const data = {
			group: 'app',
			items: [
				{ name: 'minimum_deposit_percentage', value: minumumDepositPercentage },
			],
		};
		this.props.configsBatchSetConfig(data);
	}

	render() {
		const status = this.props.status.get('configs');
		const { minumumDepositPercentage } = this.state;
		const isLoading = status === 'fetching' || status === 'submitting';
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<BreadcrumbContainer
							heading={ <IntlMessages id="config.app-settings.heading" /> }
							items={ this.breadcrumb }
							match={ this.props.match }
						/>
					</Colxx>
				</Row>

				<Separator className="mb-5" />

				<Row>
					<Colxx xxs="12">
						<Card className="mb-4">
							<LoadingOverlay active={ isLoading }>
								<CardBody>
									<Table>
										<thead>
											<tr>
												<th width="80%">Rule</th>
												<th width="20%">Status</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th>
													<h5>Minimum deposit (%)</h5>
													<span>Minimum deposit required (range: 0-100); set to 0 to skip checking</span>
												</th>
												<td>
													<InputGroup size="md" className="mb-3">
														<Input
															value={ minumumDepositPercentage || '' }
															onChange={ this.onChangeHandler }
														/>
														<InputGroupAddon addonType="append">
															%
														</InputGroupAddon>
													</InputGroup>
												</td>
											</tr>
										</tbody>
									</Table>

									<div className="mt-4 text-center">
										<Button size="lg" color="primary" onClick={ this.onSaveHandler } disabled={ isLoading }>
											SAVE
										</Button>
									</div>
								</CardBody>
							</LoadingOverlay>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}

export default connect(
	configsReducer.selector,
	{
		configsFindAllByGroup,
		configsBatchSetConfig,
		errorReset,
	}
)(PageConfigAppSettings);